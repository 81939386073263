#bienvenue{
    display: flex;
  }
  
  .left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 36px;
  }
  
  .left h1{
    font-size: 48px;
    font-family: 'Inter';
    font-weight: 900;
  }
  
  .left p{
    font-size: 16px;
  }
  
  #contactus{
    width: fit-content;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 900;
    background: #119dd9;
    color: #fff;
    border: 3px solid #fff;
    border-radius: 20px;
    padding: 8px 12px;
  }
  
  .left, .right{
    max-width: 50%;
  }
  
  .right{
    display: flex;
    justify-content: start;
    width: 50%;
  }